import request from '@/utils/request'

//开放用房预约冲突检测
export function openroomconflictdetection(params) {
    return request({
        url: '/portal/open-room-book/room-conflict-detection',
        method: 'GET',
        params
    })
}

//获取开放实验用房详情信息
export function getOpenInfo(params) {
    return request({
        url: '/portal/open-room/get-info',
        method: 'GET',
        params
    })
}



//获取开放用房列表
export function getOpenRoomList(params) {
    return request({
        url: '/portal/open-room/get-list',
        method: 'GET',
        params
    })
}
//获取开放用房所属学院列表
export function getOpenCollegeList(params) {
    return request({
        url: '/portal/open-room/get-college-list',
        method: 'GET',
        params
    })
}

//获取当前实验室预约时间段详情
export function gettimeinfo(params) {
    return request({
        url: '/portal/open-room-book/get-time-info',
        method: 'GET',
        params
    })
}

//获取当前实验室实验位各时间段预约情况
export function gettimepositioninfo(params) {
    return request({
        url: '/portal/open-room-book/get-time-position-info',
        method: 'GET',
        params
    })
}


//获取门户推荐开放用房
export function getOpenIndexList(params) {
    return request({
        url: '/portal/open-room/get-index-list',
        method: 'GET',
        params
    })
}

//获取实验设备
export function getEquipmentList(params) {
    return request({
        url: '/portal/equipment-sku/get-list',
        method: 'GET',
        params
    })
}