<template>
  <div class="firstpage">
    <div class="main-contain" v-loading="loading">
      <div class="elcarousel">
        <Carousel></Carousel>
      </div>
      <div class="notice-contain">
        <!-- <Notice></Notice> -->
      </div>
      <div class="recommended-courses">
        <Recommendedcourses></Recommendedcourses>
      </div>
      <div class="virtualsimulation-experiment">
        <Virtualsimulationexperiment></Virtualsimulationexperiment>
      </div>
      <div class="open-room">
        <Openroom></Openroom>
      </div>
      <!-- <div class="openexp-eriment">
        <Openexperiment></Openexperiment>
      </div>
      <div class="excellent-video">
        <Excellentvideo></Excellentvideo>
      </div>-->

      <div class="leader-board">
        <Leaderboard></Leaderboard>
      </div>

      <div class="links">
        <!-- <Links></Links> -->
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "@/views/firstpage/components/carousel.vue"
import Notice from "@/views/firstpage/components/notice.vue"
import Recommendedcourses from "@/views/firstpage/components/recommendedcourses.vue"
import Virtualsimulationexperiment from "@/views/firstpage/components/virtualsimulationexperiment.vue"
import Openroom from "@/views/firstpage/components/openroom.vue"
import Openexperiment from "@/views/firstpage/components/openexperiment.vue"
import Excellentvideo from "@/views/firstpage/components/excellentvideo.vue"
import Leaderboard from "@/views/firstpage/components/leaderboard.vue"
import Links from "@/views/firstpage/components/links.vue"
import { getuserinfo } from "@/api/user"
import {
  setIdentifierToken,
  setWebNameToken,
  setWebAvaterToken,
  setToken
} from "@/utils/auth"

export default {
  name: "FirstPage",
  components: {
    Carousel,
    Notice,
    Recommendedcourses,
    Virtualsimulationexperiment,
    Openroom,
    Openexperiment,
    Excellentvideo,
    Leaderboard,
    Links,
    isMobile: ''
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    // token() {
    //   return this.$route.query.token;
    // },
    // identifier() {
    //   return this.$route.query.identifier;
    // },
    // schoolid() {
    //   return this.$route.query.schoolid;
    // },
    // redirecttype() {
    //   return this.$route.query.redirecttype;
    // },
    // sign() {
    //   return this.$route.query.sign;
    // },
  },
  created () {
    this.token = this.$route.query.token
    this.identifier = this.$route.query.identifier
    this.schoolid = this.$route.query.schoolid
    if (this.token && this.identifier && this.schoolid) {
      this.$store.commit("SET_MENU_ID", 0)
      //console.log('state.menuactiveid', this.$store.state.menuactiveid);
      window.localStorage.setItem("menuId", 0)
      //console.log('menuId----------', window.localStorage.getItem("menuId"));
      setToken(this.token) // 登录成功保存token到cookie 字段为Admin-Token
      this.$store.commit("SET_TOKEN", this.token) // 登录成功保存token到vuex全局state
      //console.log('state.token', this.$store.state.token);
      // console.log("this.identifier",this.identifier);
      setIdentifierToken(this.identifier)
      // setRedirecttypeToken(this.redirecttype);
      // setSignToken(this.sign);
      // this.headers['redirecttype']= this.redirecttype;
      // this.headers['sign'] = this.sign;
      this.$store.commit("SET_Identifier", this.identifier) //获取用户身份信息
      //console.log('state.identifier----------', this.$store.state.identifier);
      this.$nextTick(() => {
        // loadingInstance.close();
        // clearInterval(timer);
        this.getuserinfo()
      })
    } else {
      this.loading = false
    }
    // let network = localStorage.getItem("network")
    // if (!network) {
    //   let ip = "s3cluster2.hzau.edu.cn"
    //   // this.ping(ip)
    // }
    if (this._isMobile()) {
      this.isMobile = 0
      // this.$router.push({ path: "/home/wisdompracticeteaching/mainpage" })
      this.$router.push({ path: "/home" })
    } else {
      this.isMobile = 1
      this.$router.push({ path: "/home" })
    }
  },
  methods: {
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    // ping(ip) {
    //   var img = new Image()
    //   var start = new Date().getTime()
    //   var flag = false
    //   var isCloseWifi = true
    //   var hasFinish = false
    //   img.onload = function () {
    //     if (!hasFinish) {
    //       flag = true
    //       hasFinish = true
    //       //console.log('Ping ' + ip + ' success. ');
    //       // alert("成功a"+ip);
    //       localStorage.setItem("network", 1)
    //     }
    //   }
    //   img.onerror = function () {
    //     if (!hasFinish) {
    //       if (!isCloseWifi) {
    //         flag = true
    //         //console.log('Ping ' + ip + ' success. ');
    //         // alert("成功b"+ip);
    //         localStorage.setItem("network", 1)
    //       } else {
    //         //console.log('network is not working!');
    //         localStorage.setItem("network", 0)
    //       }
    //       hasFinish = true
    //     }
    //   }
    //   setTimeout(function () {
    //     isCloseWifi = false
    //     //console.log('network is working, start ping...');
    //     // alert("开始测试"+ip);
    //   }, 2)
    //   img.src = 'http://' + ip + '/' + start
    //   var timer = setTimeout(function () {
    //     if (!flag) {
    //       hasFinish = true
    //       flag = false
    //       //console.log('Ping ' + ip + ' fail. ');
    //       // alert("失败c"+ip);
    //       localStorage.setItem("network", 0)
    //     }
    //   }, 3000)
    // },

    getuserinfo () {
      getuserinfo()
        .then((response) => {
          if (response.code === 0) {
            //console.log("response", response);
            this.$store.commit("SET_Username", response.data.name)
            setWebNameToken(response.data.name)
            this.$store.commit("SET_Useravater", response.data.avatar)
            localStorage.setItem("username", response.data.name)
            localStorage.setItem("usernumber", response.data.username)
            setWebAvaterToken(response.data.avatar)
            this.loading = false
            location.reload()
            // this.$router.push({ path: "/home" });
          }
        })
        .catch((error) => { })
    }
  },
};
</script>
<style scoped lang="scss">
.firstpage {
  background: #ffffff;

  @media screen and (max-width: 640px) {
    .main-contain {
      margin-top: 66px;
      .elcarousel {
        margin: 0 auto;
      }

      .notice-contain {
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .recommended-courses {
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
      }

      .virtualsimulation-experiment {
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .open-room {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .openexp-eriment {
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .excellent-video {
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .leader-board {
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 20px;
      }
    }
  }

  @media screen and (min-width: 640px) and (max-width: 1200px) {
    .main-contain {
      // margin-top: 100px;

      .elcarousel {
        margin: 0 auto;
        margin-top: 70px;
      }

      .el_main {
        padding: 0 !important;
      }

      .notice-contain {
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .recommended-courses {
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
      }

      .virtualsimulation-experiment {
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .open-room {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .openexp-eriment {
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .excellent-video {
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .leader-board {
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .main-contain {
      // margin-top: 110px;

      .elcarousel {
        margin: 0 auto;
      }

      .notice-contain {
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .recommended-courses {
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
      }

      .virtualsimulation-experiment {
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .open-room {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .openexp-eriment {
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .excellent-video {
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .leader-board {
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
      }
    }
  }
}
</style>
