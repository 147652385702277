<template>
  <div class="leaderboard">
    <div class="head-contain">
      <el-image class="elimage" :src="image" />
      <div class="title-text">排行榜</div>
    </div>
    <div class="notice">
      <!-- 实验室使用排行 -->
      <div class="notice-common notice-left">
        <div class="notice-left-head">
          <div class="notice-left-head-title">实验室使用排行</div>
          <div class="notice-left-head-more" @click="change_bt_one">
            <div class="more-title">换一换</div>
            <div class="more-icon">
              <el-image class="elimage" :src="huan_icon" />
            </div>
          </div>
        </div>
        <div class="notice-left-line"></div>
        <div class="notice-left-content">
          <!-- <el-image class="elimage2" :src="imagebg" /> -->
          <!-- <div class="conten-item" v-for="(item,index) in labdata" :key='index'>
            <div class="conten-item-title-title">
              <el-image class="elimage" :src="icon1" />
              <div class="title">本科生创新实验室本科生创</div>
            </div>
            <div class="conten-item-title-time">使用时长：2365天18小时25</div>
          </div> -->
          <div class="conten-item" v-for="(item, index) in labdata" :key="index">
            <div class="conten-item-title-title">
              <template v-if="labdatastrip == 1">
                <el-image v-if="index == 0" class="elimage" :src="icon1" />
                <el-image v-if="index == 1" class="elimage" :src="icon2" />
                <el-image v-if="index == 2" class="elimage" :src="icon3" />
                <div v-if="index == 3" class="icon_num">
                  <span class="num">4</span>
                </div>
                <div v-if="index == 4" class="icon_num">
                  <span class="num">5</span>
                </div>
              </template>
              <template v-else>
                <div class="icon_num">
                  <span class="num">{{ index + 6 }}</span>
                </div>
              </template>
              <div class="title">{{ item.name }}</div>
            </div>
            <div class="conten-item-title-time">使用时长：{{ item.times }}</div>
          </div>
        </div>
      </div>

      <!-- 课程排行 -->
      <div class="notice-common notice-center">
        <div class="notice-center-head">
          <div class="notice-center-head-title">课程排行</div>
          <div class="notice-center-head-more" @click="change_bt_two()">
            <div class="more-title">换一换</div>
            <div class="more-icon">
              <el-image class="elimage" :src="huan_icon" />
            </div>
          </div>
        </div>
        <div class="notice-center-line"></div>
        <div class="notice-center-content">
          <div class="conten-item" style="cursor: pointer" v-for="(item, index) in coursedata" :key="index"
            @click="coursedata_href(item)">
            <div class="conten-item-title-title">
              <template v-if="coursedatastrip == 1">
                <el-image v-if="index == 0" class="elimage" :src="icon1" />
                <el-image v-if="index == 1" class="elimage" :src="icon2" />
                <el-image v-if="index == 2" class="elimage" :src="icon3" />
                <div v-if="index == 3" class="icon_num">
                  <span class="num">4</span>
                </div>
                <div v-if="index == 4" class="icon_num">
                  <span class="num">5</span>
                </div>
              </template>
              <template v-else>
                <div class="icon_num">
                  <span class="num">{{ index + 6 }}</span>
                </div>
              </template>
              <div class="title">{{ item.name }}</div>
            </div>
            <div class="conten-item-title-time">
              主讲老师：{{ item.teacher_name }} | 参与人数：{{
              item.join_count
              }}
            </div>
          </div>
        </div>
      </div>
      <!-- 互动排行 -->
      <div class="notice-common notice-right">
        <div class="notice-center-head">
          <div class="notice-center-head-title">互动排行</div>
          <div class="notice-center-head-more" @click="change_bt_three">
            <div class="more-title">换一换</div>
            <div class="more-icon">
              <el-image class="elimage" :src="huan_icon" />
            </div>
          </div>
        </div>
        <div class="notice-center-line"></div>
        <div class="notice-center-content">
          <div class="conten-item" style="cursor: pointer" v-for="(item, index) in qadata" :key="index"
            @click="qadata_href(item)">
            <div class="conten-item-title-title">
              <template v-if="qadatastrip == 1">
                <el-image v-if="index == 0" class="elimage" :src="icon1" />
                <el-image v-if="index == 1" class="elimage" :src="icon2" />
                <el-image v-if="index == 2" class="elimage" :src="icon3" />
                <div v-if="index == 3" class="icon_num">
                  <span class="num">4</span>
                </div>
                <div v-if="index == 4" class="icon_num">
                  <span class="num">5</span>
                </div>
              </template>
              <template v-else>
                <div class="icon_num">
                  <span class="num">{{ index + 6 }}</span>
                </div>
              </template>
              <div class="title">{{ item.name }}</div>
            </div>
            <div class="conten-item-title-time">
              互动数：{{ item.count }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 虚拟仿真排行 -->
    <div class="bottom_con">
      <div class="head_con">
        <div class="title">虚拟仿真排行</div>
        <div class="bt_right" @click="change_bt_four()">
          <div class="more-title">换一换</div>
          <el-image class="elimage" :src="huan_icon" />
        </div>
      </div>
      <div class="bt_con">
        <div :class="btid == 0 ? 'bt bt_active' : 'bt'" @click="clickbt(0)">
          人气指数
        </div>
        <div :class="btid == 1 ? 'bt bt_active' : 'bt'" @click="clickbt(1)">
          共享指数
        </div>
        <div :class="btid == 2 ? 'bt bt_active' : 'bt'" @click="clickbt(2)">
          吸引指数
        </div>
      </div>
      <div class="con_body">
        <div class="item_con">
          <div @click="todetail(item)" class="item" v-for="(item, index) in vrdata" :key="index">
            <template>
              <div class="image-contain" v-if="item.cover">
                <el-image class="elimage" :src="item.cover" @error="handleError" />
              </div>
              <div class="image-contain1" v-else>
                <el-image class="elimage" :src="image11" />
              </div>
              <div
                :class="{ 'china_class': item.level == '国家级', 'china_class_1': item.level == '校级', 'china_class_2': item.level == '省级' }">
                <span>{{ item.level }}</span>
              </div>
            </template>
            <!-- <el-image class="elimage" :src="item.cover" /> -->
            <div class="item_r">
              <div class="item_t">
                {{ item.name }}
              </div>
              <template v-if="btid == 0">
                <div class="item_c">
                  <!-- <el-image class="elimage" :src="imagepeople" /> -->
                  <div class="num">负责人：{{ item.leader_name?.toString() }}</div>
                  <div class="num">参与人数：{{ item.user_count }}</div>
                </div>
              </template>
              <template v-if="btid == 1">
                <div class="item_c">
                  <!-- <el-image class="elimage" :src="imagepeople" /> -->
                  <div class="num">负责人：{{ item.leader_name?.toString() }}</div>
                  <div class="num">校外参与人数：{{ item.user_count }}</div>
                </div>
              </template>
              <template v-if="btid == 2">
                <div class="item_c">
                  <!-- <el-image class="elimage" :src="collecticon1" /> -->
                  <div class="num">负责人：{{ item.leader_name }}</div>
                  <div class="num">收藏人数：{{ item.user_count }}</div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getlabrank,
  getvrrank,
  getcourserank,
  getqarank,
} from "@/api/leaderboard";
import { getcourseinfo } from "@/api/course";
export default {
  name: "Leaderboard",
  components: {},
  data() {
    return {
      btid: 0,
      imagebg: require("@/assets/new/bg.png"),
      imagetest: require("@/assets/new/4.jpg"),
      imagepeople: require("@/assets/new/people.png"),
      huan_icon: require("@/assets/new/huan.png"),
      image: require("@/assets/new/排行榜.png"),
      image1: require("@/assets/new/right.png"),
      icon1: require("@/assets/new/paihangbang1.png"),
      icon2: require("@/assets/new/paihangbang2.png"),
      icon3: require("@/assets/new/paihangbang3.png"),
      image11: require("@/assets/vr.png"),
      collecticon1: require("@/assets/newchange/collection.png"),
      labdata: [],
      labtype: -1,
      coursedata: "",
      coursetype: -1,
      qadata: "",
      qatype: -1,
      vrdata: "",
      vrtype: -1,
      labdatacount: '',
      vrdatastrip: '',
      coursedatastrip: '',
      qadatastrip: '',
      vrdatatotal: '',
      coursedatatotal: '',
      qadatatotal: '',
      labdatastrip: '',
      labdatatotal: '',
    };
  },
  computed: {},
  created() {
    this.getlabrank({
      per_page: 5,
    });
    this.getcourserank({
      per_page: 5,
    });
    this.getqarank({
      per_page: 5,
    });
    this.getvrrank({ sort: 1, page: 1 });
  },
  methods: {
    handleError(e) {
      // 当图片加载失败时，将图片地址设置为默认图片
      e.target.src = this.image11;
      // 可以添加额外的处理，比如提示用户或者进行日志记录
    },
    clickbt(id) {
      this.btid = id;
      // this.vrtype == -1;
      switch (id) {
        case 0: //人气指数
          this.getvrrank({ sort: 1, page: 1 });
          break;
        case 1: //共享指数
          this.getvrrank({ sort: 2, page: 1 });
          break;
        case 2: //吸引指数
          this.getvrrank({ sort: 3, page: 1 });
          break;
      }
    },
    //实验室使用排行
    change_bt_one() {
      if (this.labdatatotal <= 5) {
        this.$message.success("已是最新排行数据");
        return false;
      } else {
        this.labdatastrip = this.labdatastrip == 1 ? 2 : 1;
        this.getlabrank({
          page: this.labdatastrip,
          per_page: 5,
        });
      }
    },

    //课程排行
    change_bt_two() {
      if (this.coursedatatotal <= 5) {
        this.$message.success("已是最新排行数据");
        return false;
      } else {
        this.coursedatastrip = this.coursedatastrip == 1 ? 2 : 1;
        this.getcourserank({
          page: this.coursedatastrip,
          per_page: 5,
        });
      }

    },
    //互动排行
    change_bt_three() {
      if (this.qadatatotal <= 5) {
        this.$message.success("已是最新排行数据");
        return false;
      } else {
        this.qadatastrip = this.qadatastrip == 1 ? 2 : 1;
        this.getqarank({
          page: this.qadatastrip,
          per_page: 5,
        });
      }

    },

    //虚拟仿真排行
    change_bt_four() {
      if (this.vrdatatotal <= 6) {
        this.$message.success("已是最新排行数据");
        return false;
      } else {
        this.vrdatastrip = this.vrdatastrip == 1 ? 2 : 1
        this.getvrrank({
          sort: this.btid + 1,
          page: this.vrdatastrip,
        });
      }

    },
    todetail(item) {
      window.localStorage.setItem("menuId", 1);
      this.$store.dispatch("setmenuid", 1);
      this.$router.push({
        path: "/home/virtualsimulationexperiment/experimentdetails",
        query: {
          id: item.id,
        },
      });
    },
    //课程排行
    qadata_href(item) {
      //关联类型，0：无关联，1：关联课程，2：关联虚拟仿真
      if (item.related_type == 1) {
        window.localStorage.setItem("menuId", 2);
        this.$store.dispatch("setmenuid", 2);
        //已经加入课程
        this.$router.push({
          path:
            "/home/wisdompracticeteaching/courselearning?course_sn=" + item.sn,
        });
      } else {
        window.localStorage.setItem("menuId", 1);
        this.$store.dispatch("setmenuid", 1);
        this.$router.push({
          path: "/home/virtualsimulationexperiment/experimentdetails",
          query: {
            id: item.sn,
          },
        });
      }
    },
    coursedata_href(item) {
      window.localStorage.setItem("menuId", 2);
      this.$store.dispatch("setmenuid", 2);
      if (item.is_join == 1) {
        //已经加入课程
        this.$router.push({
          path:
            "/home/wisdompracticeteaching/courselearning?course_sn=" +
            item.course_sn,
        });
      } else {
        this.$router.push({
          path:
            "/home/wisdompracticeteaching/coursedetails?course_sn=" +
            item.course_sn,
        });
      }
    },
    //实验室使用排行
    getlabrank(params) {
      this.labdata = [];
      getlabrank(params)
        .then((response) => {
          if (response.code === 0) {
            this.labdatacount = response.data.data.lenght;
            this.labdata = response.data.data;
            this.labdatastrip = response.data.current_page;
            this.labdatatotal = response.data.total;
          }
        })
        .catch((error) => { });
    },
    //课程排行
    getcourserank(params) {
      this.coursedata = [];
      getcourserank(params)
        .then((response) => {
          if (response.code === 0) {
            this.coursedata = response.data.data;
            this.coursedatastrip = response.data.current_page;
            this.coursedatatotal = response.data.total;
          }
        })
        .catch((error) => { });
    },
    //互动排行
    getqarank(params) {
      this.qadata = [];
      getqarank(params)
        .then((response) => {
          if (response.code === 0) {
            this.qadata = response.data.data;
            this.qadatastrip = response.data.current_page;
            this.qadatatotal = response.data.total;
          }
        })
        .catch((error) => { });
    },
    //虚拟仿真排行
    getvrrank(params) {
      this.vrdata = [];
      getvrrank(params)
        .then((response) => {
          if (response.code === 0) {
            this.vrdata = response.data.data;
            this.vrdatastrip = response.data.current_page
            this.vrdatatotal = response.data.total
          }
        })
        .catch((error) => { });
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .leaderboard {
    //   border: 1px solid red;
    // padding-left: 10px;
    // padding-right: 10px;
    width: 100%;

    // height: 100%;
    .head-contain {
      // border: 1px solid red;
      margin-top: 10px;
      margin-bottom: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .elimage {
        margin-right: 10px;
        width: 25px;
        height: 25px;
      }

      .title-text {
        margin-left: 4px;
        font-size: 20px;

        font-weight: bold;
        color: #000000;
      }
    }

    .notice {
      // border: 1px solid red;
      margin-bottom: 20px;
      width: 100%;
      height: 100%;
      // background: #f7f7f7;
      // display: flex;
      // justify-content: space-between;
      // align-items: center;

      .notice-common {
        // border: 1px solid red;
        // width: 560px;
        flex: 1;
        flex-shrink: 1;
        height: 390px;
        background: #ffffff;
        border-radius: 4px;
      }

      .notice-left {
        // border: 1px solid red;
        background: #f9f9f9;

        .notice-left-head {
          margin-top: 10px;
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 10px;

          //   border: 1px solid red;
          .notice-left-head-title {
            margin-left: 19px;
            font-size: 20px;

            font-weight: bold;
            color: #000000;
          }

          .notice-left-head-more {
            margin-right: 10px;
            width: 80px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #ededed;
            border-radius: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            user-select: none;
            cursor: pointer;

            .more-title {
              margin-left: 10px;
              font-size: 12px;

              font-weight: 500;
              color: #666666;
            }

            .more-icon {
              // border: 1px solid red;
              margin-right: 10px;

              .elimage {
                width: 12px;
                height: 12px;
              }
            }
          }
        }

        .notice-left-line {
          margin-top: 10px;
          margin-bottom: 10px;
          border: 1px solid #cccccc;
        }

        .notice-left-content {
          position: relative;

          .elimage2 {
            position: absolute;
            top: 60px;
            left: 340px;
            width: 200px;
            height: 200px;
          }

          .conten-item {
            margin-bottom: 20px;
            cursor: pointer;

            .conten-item-title-title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-left: 20px;

              .elimage {
                margin-right: 6px;
                width: 27px;
                height: 27px;
              }

              .icon_num {
                margin-right: 6px;
                // padding-right: 4px;
                width: 24px;
                height: 24px;
                background: #409eff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .num {
                  margin-right: 4px;
                  font-size: 14px;
                  font-weight: bold;
                  font-style: italic;
                  color: #ffffff;
                }
              }

              .title {
                font-size: 16px;
                font-weight: 500;
                color: #000;
              }

              .title:hover {
                color: #3d84ff;
              }
            }

            .conten-item-title-time {
              margin-top: 4px;
              margin-right: 10px;
              padding-left: 52px;
              font-size: 12px;
              font-weight: 400;
              color: #999;
            }

            .conten-item-title-time:hover {
              color: #b29873;
            }
          }
        }
      }

      .notice-center {
        // border: 1px solid red;
        background: #f9f9f9;
        // margin-left: 40px;

        .notice-center-head {
          margin-top: 10px;
          margin-bottom: 10px;

          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 10px;

          //   border: 1px solid red;
          .notice-center-head-title {
            margin-left: 19px;
            font-size: 20px;

            font-weight: bold;
            color: #000000;
          }

          .notice-center-head-more {
            margin-right: 10px;
            width: 80px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #ededed;
            border-radius: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            user-select: none;
            cursor: pointer;

            .more-title {
              margin-left: 10px;
              font-size: 12px;

              font-weight: 500;
              color: #666666;
            }

            .more-icon {
              margin-right: 10px;

              .elimage {
                width: 12px;
                height: 12px;
              }
            }
          }
        }

        .notice-center-line {
          margin-top: 10px;
          margin-bottom: 10px;
          border: 1px solid #cccccc;
        }

        .notice-center-content {
          .conten-item {
            margin-bottom: 20px;

            .conten-item-title-title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-left: 20px;

              .elimage {
                margin-right: 6px;
                width: 27px;
                height: 27px;
              }

              .icon_num {
                margin-right: 6px;
                // padding-right: 4px;
                width: 24px;
                height: 24px;
                background: #409eff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .num {
                  margin-right: 4px;
                  font-size: 14px;
                  font-weight: bold;
                  font-style: italic;
                  color: #ffffff;
                }
              }

              .title {
                font-size: 16px;
                font-weight: 500;
                color: #000;
              }

              .title:hover {
                color: #3d84ff;
              }
            }

            .conten-item-title-time {
              margin-top: 4px;
              margin-right: 10px;
              padding-left: 52px;
              font-size: 12px;
              font-weight: 400;
              color: #999;
            }

            .conten-item-title-time:hover {
              color: #b29873;
            }
          }
        }
      }

      .notice-right {
        background: #f9f9f9;
        // margin-left: 40px;

        .notice-center-head {
          margin-top: 10px;
          margin-bottom: 10px;

          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 10px;

          //   border: 1px solid red;
          .notice-center-head-title {
            margin-left: 19px;
            font-size: 20px;

            font-weight: bold;
            color: #000000;
          }

          .notice-center-head-more {
            margin-right: 10px;
            width: 80px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #ededed;
            border-radius: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            user-select: none;
            cursor: pointer;

            .more-title {
              margin-left: 10px;
              font-size: 12px;

              font-weight: 500;
              color: #666666;
            }

            .more-icon {
              margin-right: 10px;

              .elimage {
                width: 12px;
                height: 12px;
              }
            }
          }
        }

        .notice-center-line {
          margin-top: 10px;
          margin-bottom: 10px;
          border: 1px solid #cccccc;
        }

        .notice-center-content {
          .conten-item {
            margin-bottom: 20px;

            .conten-item-title-title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-left: 20px;

              .elimage {
                margin-right: 6px;
                width: 27px;
                height: 27px;
              }

              .icon_num {
                margin-right: 6px;
                // padding-right: 4px;
                width: 24px;
                height: 24px;
                background: #409eff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .num {
                  margin-right: 4px;
                  font-size: 14px;
                  font-weight: bold;
                  font-style: italic;
                  color: #ffffff;
                }
              }

              .title {
                font-size: 16px;
                font-weight: 500;
                color: #000;
              }

              .title:hover {
                color: #3d84ff;
              }
            }

            .conten-item-title-time {
              margin-top: 4px;
              margin-right: 10px;
              padding-left: 52px;
              font-size: 12px;
              font-weight: 400;
              color: #999;
            }

            .conten-item-title-time:hover {
              color: #b29873;
            }
          }
        }
      }
    }

    .bottom_con {
      // border: 1px solid red;
      margin-top: 20px;
      // margin-bottom: 60px;
      width: 100%;
      min-height: 460px;
      background: #f9f9f9;

      .head_con {
        padding-top: 14px;
        padding-left: 20px;
        padding-bottom: 16px;
        // width: 100%;
        height: 23px;

        border-bottom: 2px solid #cccccc;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          font-size: 20px;
          font-weight: bold;
          color: #000000;
        }

        .bt_right {
          margin-right: 10px;
          width: 80px;
          height: 28px;
          background: #ffffff;
          border: 1px solid #ededed;
          border-radius: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          user-select: none;
          cursor: pointer;

          .more-title {
            margin-left: 10px;
            font-size: 12px;

            font-weight: 500;
            color: #666666;
          }

          .elimage {
            margin-right: 10px;
            width: 12px;
            height: 12px;
          }
        }
      }

      .bt_con {
        // padding-top: 25px;
        // padding-left: 20px;
        // margin-bottom: 50px;
        // height: 17px;
        // display: flex;
        // justify-content: flex-start;
        // align-items: center;
        background: #fff;
        height: 50px;
        line-height: 50px;

        .bt {
          // margin-right: 90px;
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          user-select: none;
          cursor: pointer;
          width: calc(100% / 3);
          float: left;
          text-align: center;
        }

        .bt_active {
          color: #409eff;
        }
      }

      .con_body {
        // border: 1px solid red;
        // height: 305px;
        // padding: 20px;

        .item_con {
          // border: 1px solid red;
          // height: 100%;
          // display: flex;
          // justify-content: flex-start;
          // align-items: center;
          // flex-wrap: wrap;
          // float: left;
          // width: calc((100% - 60px) / 3);

          // position: relative;
          width: 100%;
          overflow: hidden;
          background: #fff;

          .item {
            margin-right: 10px;
            margin-bottom: 20px;
            // border: 1px solid red;
            // display: flex;
            cursor: pointer;
            // justify-content: flex-start;
            // align-items: center;
            float: left;
            width: calc((100% - 20px) / 2);
            .image-contain {
              width: 100%;
              height: 125px;
              float: left;
              position: relative;
              background: aliceblue;
              .elimage {
                display: inline-block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage:hover,
              .elimage:focus,
              .elimage:active {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              .elimage {
                width: 100%;
                height: 100%;
              }
            }

            .image-contain1 {
              width: 100%;
              height: 125px;
              float: left;
              position: relative;
              // background: #daebfc;
              text-align: center;
              background: aliceblue;
              .elimage {
                display: inline-block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage:hover,
              .elimage:focus,
              .elimage:active {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              .elimage {
                width: 70%;
                height: 100%;
              }
            }


            .item_r {
              // border: 1px solid red;
              height: 100%;
              width: 100%;
              float: left;
              // display: flex;
              // flex: 1;
              // flex-direction: column;
              // justify-content: flex-start;
              // align-items: flex-start;

              .item_t {
                margin-top: 4px;
                margin-left: 10px;
                font-size: 14px;
                font-weight: 500;
                color: #000000;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }

              .item_c {
                margin-top: 10px;
                margin-left: 10px;

                // display: flex;
                // justify-content: flex-start;
                // align-items: center;

                .elimage {
                  width: 14px;
                  height: 14px;
                }

                .num {
                  font-size: 14px;
                  font-weight: 500;
                  color: #999999;
                  margin-bottom: 10px;
                }
              }
            }

            .china_class {
              position: absolute;
              top: 0px;
              right: 0;
              // left: 154px;
              background: #ff0c0c;
              font-size: 12px;
              color: #fff;
              width: 50px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              border-radius: 0px 0px 0px 14px;
            }

            .china_class_1 {
              position: absolute;
              top: 0px;
              right: 0;
              background: #4980fe;
              font-size: 12px;
              color: #fff;
              width: 50px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              border-radius: 0px 0px 0px 14px;
            }

            .china_class_2 {
              position: absolute;
              top: 0px;
              right: 0;
              background: #FBC248;
              font-size: 12px;
              color: #fff;
              width: 50px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              border-radius: 0px 0px 0px 14px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 640px) and (max-width: 1200px) {
  .leaderboard {
    //   border: 1px solid red;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;

    // height: 100%;
    .head-contain {
      // border: 1px solid red;
      margin-top: 10px;
      margin-bottom: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .elimage {
        margin-right: 10px;
        width: 25px;
        height: 25px;
      }

      .title-text {
        margin-left: 4px;
        font-size: 20px;

        font-weight: bold;
        color: #000000;
      }
    }

    .notice {
      // border: 1px solid red;
      margin-bottom: 20px;
      width: 100%;
      height: 100%;
      // background: #f7f7f7;
      // display: flex;
      // justify-content: space-between;
      // align-items: center;

      .notice-common {
        // border: 1px solid red;
        // width: 560px;
        flex: 1;
        flex-shrink: 1;
        height: 390px;
        background: #ffffff;
        border-radius: 4px;
      }

      .notice-left {
        // border: 1px solid red;
        background: #f9f9f9;

        .notice-left-head {
          margin-top: 10px;
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 10px;

          //   border: 1px solid red;
          .notice-left-head-title {
            margin-left: 19px;
            font-size: 20px;

            font-weight: bold;
            color: #000000;
          }

          .notice-left-head-more {
            margin-right: 10px;
            width: 80px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #ededed;
            border-radius: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            user-select: none;
            cursor: pointer;

            .more-title {
              margin-left: 10px;
              font-size: 12px;

              font-weight: 500;
              color: #666666;
            }

            .more-icon {
              // border: 1px solid red;
              margin-right: 10px;

              .elimage {
                width: 12px;
                height: 12px;
              }
            }
          }
        }

        .notice-left-line {
          margin-top: 10px;
          margin-bottom: 10px;
          border: 1px solid #cccccc;
        }

        .notice-left-content {
          position: relative;

          .elimage2 {
            position: absolute;
            top: 60px;
            left: 340px;
            width: 200px;
            height: 200px;
          }

          .conten-item {
            margin-bottom: 20px;
            cursor: pointer;

            .conten-item-title-title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-left: 20px;

              .elimage {
                margin-right: 6px;
                width: 27px;
                height: 27px;
              }

              .icon_num {
                margin-right: 6px;
                // padding-right: 4px;
                width: 24px;
                height: 24px;
                background: #409eff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .num {
                  margin-right: 4px;
                  font-size: 14px;
                  font-weight: bold;
                  font-style: italic;
                  color: #ffffff;
                }
              }

              .title {
                font-size: 16px;
                font-weight: 500;
                color: #000;
              }

              .title:hover {
                color: #3d84ff;
              }
            }

            .conten-item-title-time {
              margin-top: 4px;
              margin-right: 10px;
              padding-left: 52px;
              font-size: 12px;
              font-weight: 400;
              color: #999;
            }

            .conten-item-title-time:hover {
              color: #b29873;
            }
          }
        }
      }

      .notice-center {
        // border: 1px solid red;
        background: #f9f9f9;
        // margin-left: 40px;

        .notice-center-head {
          margin-top: 10px;
          margin-bottom: 10px;

          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 10px;

          //   border: 1px solid red;
          .notice-center-head-title {
            margin-left: 19px;
            font-size: 20px;

            font-weight: bold;
            color: #000000;
          }

          .notice-center-head-more {
            margin-right: 10px;
            width: 80px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #ededed;
            border-radius: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            user-select: none;
            cursor: pointer;

            .more-title {
              margin-left: 10px;
              font-size: 12px;

              font-weight: 500;
              color: #666666;
            }

            .more-icon {
              margin-right: 10px;

              .elimage {
                width: 12px;
                height: 12px;
              }
            }
          }
        }

        .notice-center-line {
          margin-top: 10px;
          margin-bottom: 10px;
          border: 1px solid #cccccc;
        }

        .notice-center-content {
          .conten-item {
            margin-bottom: 20px;

            .conten-item-title-title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-left: 20px;

              .elimage {
                margin-right: 6px;
                width: 27px;
                height: 27px;
              }

              .icon_num {
                margin-right: 6px;
                // padding-right: 4px;
                width: 24px;
                height: 24px;
                background: #409eff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .num {
                  margin-right: 4px;
                  font-size: 14px;
                  font-weight: bold;
                  font-style: italic;
                  color: #ffffff;
                }
              }

              .title {
                font-size: 16px;
                font-weight: 500;
                color: #000;
              }

              .title:hover {
                color: #3d84ff;
              }
            }

            .conten-item-title-time {
              margin-top: 4px;
              margin-right: 10px;
              padding-left: 52px;
              font-size: 12px;
              font-weight: 400;
              color: #999;
            }

            .conten-item-title-time:hover {
              color: #b29873;
            }
          }
        }
      }

      .notice-right {
        background: #f9f9f9;
        // margin-left: 40px;

        .notice-center-head {
          margin-top: 10px;
          margin-bottom: 10px;

          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 10px;

          //   border: 1px solid red;
          .notice-center-head-title {
            margin-left: 19px;
            font-size: 20px;

            font-weight: bold;
            color: #000000;
          }

          .notice-center-head-more {
            margin-right: 10px;
            width: 80px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #ededed;
            border-radius: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            user-select: none;
            cursor: pointer;

            .more-title {
              margin-left: 10px;
              font-size: 12px;

              font-weight: 500;
              color: #666666;
            }

            .more-icon {
              margin-right: 10px;

              .elimage {
                width: 12px;
                height: 12px;
              }
            }
          }
        }

        .notice-center-line {
          margin-top: 10px;
          margin-bottom: 10px;
          border: 1px solid #cccccc;
        }

        .notice-center-content {
          .conten-item {
            margin-bottom: 20px;

            .conten-item-title-title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-left: 20px;

              .elimage {
                margin-right: 6px;
                width: 27px;
                height: 27px;
              }

              .icon_num {
                margin-right: 6px;
                // padding-right: 4px;
                width: 24px;
                height: 24px;
                background: #409eff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .num {
                  margin-right: 4px;
                  font-size: 14px;
                  font-weight: bold;
                  font-style: italic;
                  color: #ffffff;
                }
              }

              .title {
                font-size: 16px;
                font-weight: 500;
                color: #000;
              }

              .title:hover {
                color: #3d84ff;
              }
            }

            .conten-item-title-time {
              margin-top: 4px;
              margin-right: 10px;
              padding-left: 52px;
              font-size: 12px;
              font-weight: 400;
              color: #999;
            }

            .conten-item-title-time:hover {
              color: #b29873;
            }
          }
        }
      }
    }

    .bottom_con {
      // border: 1px solid red;
      margin-top: 40px;
      margin-bottom: 60px;
      width: 100%;
      min-height: 460px;
      background: #f9f9f9;

      .head_con {
        padding-top: 14px;
        padding-left: 20px;
        padding-bottom: 16px;
        // width: 100%;
        height: 23px;

        border-bottom: 2px solid #cccccc;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          font-size: 20px;
          font-weight: bold;
          color: #000000;
        }

        .bt_right {
          margin-right: 10px;
          width: 80px;
          height: 28px;
          background: #ffffff;
          border: 1px solid #ededed;
          border-radius: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          user-select: none;
          cursor: pointer;

          .more-title {
            margin-left: 10px;
            font-size: 12px;

            font-weight: 500;
            color: #666666;
          }

          .elimage {
            margin-right: 10px;
            width: 12px;
            height: 12px;
          }
        }
      }

      .bt_con {
        // padding-top: 25px;
        // padding-left: 20px;
        // margin-bottom: 50px;
        // height: 17px;
        // display: flex;
        // justify-content: flex-start;
        // align-items: center;
        background: #fff;
        height: 50px;
        line-height: 50px;

        .bt {
          // margin-right: 90px;
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          user-select: none;
          cursor: pointer;
          width: calc(100% / 3);
          float: left;
          text-align: center;
        }

        .bt_active {
          color: #409eff;
        }
      }

      .con_body {
        // border: 1px solid red;
        // height: 305px;
        // padding: 20px;

        .item_con {
          // border: 1px solid red;
          // height: 100%;
          // display: flex;
          // justify-content: flex-start;
          // align-items: center;
          // flex-wrap: wrap;
          // float: left;
          // width: calc((100% - 60px) / 3);

          // position: relative;
          width: 100%;
          overflow: hidden;
          background: #fff;

          .item {
            margin-right: 10px;
            margin-bottom: 20px;
            // border: 1px solid red;
            // display: flex;
            // cursor: pointer;
            // justify-content: flex-start;
            // align-items: center;
            float: left;
            width: calc((100% - 30px) / 3);

            .image-contain {
              width: 100%;
              height: 200px;
              float: left;
              position: relative;
              background: aliceblue;

              .elimage {
                display: inline-block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage:hover,
              .elimage:focus,
              .elimage:active {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              .elimage {
                width: 100%;
                height: 100%;
              }
            }

            .image-contain1 {
              width: 100%;
              height: 200px;
              float: left;
              position: relative;
              // background: #daebfc;
              text-align: center;
              background: aliceblue;
              .elimage {
                display: inline-block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage:hover,
              .elimage:focus,
              .elimage:active {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              .elimage {
                width: 70%;
                height: 100%;
              }
            }


            .item_r {
              // border: 1px solid red;
              height: 100%;
              width: 100%;
              float: left;
              // display: flex;
              // flex: 1;
              // flex-direction: column;
              // justify-content: flex-start;
              // align-items: flex-start;

              .item_t {
                margin-top: 4px;
                margin-left: 10px;
                font-size: 16px;
                font-weight: 500;
                color: #000000;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }

              .item_c {
                margin-top: 10px;
                margin-left: 10px;

                // display: flex;
                // justify-content: flex-start;
                // align-items: center;

                .elimage {
                  width: 14px;
                  height: 14px;
                }

                .num {
                  font-size: 14px;
                  font-weight: 500;
                  color: #999999;
                  margin-bottom: 10px;
                }
              }
            }

            .china_class {
              position: absolute;
              top: 0px;
              right: 0;
              // left: 154px;
              background: #ff0c0c;
              font-size: 12px;
              color: #fff;
              width: 50px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              border-radius: 0px 0px 0px 14px;
            }

            .china_class_1 {
              position: absolute;
              top: 0px;
              right: 0;
              background: #4980fe;
              font-size: 12px;
              color: #fff;
              width: 50px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              border-radius: 0px 0px 0px 14px;
            }

            .china_class_2 {
              position: absolute;
              top: 0px;
              right: 0;
              background: #FBC248;
              font-size: 12px;
              color: #fff;
              width: 50px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              border-radius: 0px 0px 0px 14px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .leaderboard {
    //   border: 1px solid red;
    padding-left: 50px;
    padding-right: 50px;
    width: 1760px;

    // height: 100%;
    .head-contain {
      // border: 1px solid red;
      margin-top: 10px;
      margin-bottom: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .elimage {
        margin-right: 10px;
        width: 32px;
        height: 32px;
      }

      .title-text {
        margin-left: 4px;
        font-size: 26px;

        font-weight: 500;
        color: #000000;
      }
    }

    .notice {
      // border: 1px solid red;
      margin-bottom: 20px;
      width: 100%;
      height: 100%;
      // background: #f7f7f7;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .notice-common {
        // border: 1px solid red;
        // width: 560px;
        flex: 1;
        flex-shrink: 1;
        height: 390px;
        background: #ffffff;
        border-radius: 4px;
      }

      .notice-left {
        // border: 1px solid red;
        background: #f9f9f9;

        .notice-left-head {
          margin-top: 10px;
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 10px;

          //   border: 1px solid red;
          .notice-left-head-title {
            margin-left: 19px;
            font-size: 22px;

            font-weight: 400;
            color: #000000;
          }

          .notice-left-head-more {
            margin-right: 10px;
            width: 80px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #ededed;
            border-radius: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            user-select: none;
            cursor: pointer;

            .more-title {
              margin-left: 10px;
              font-size: 12px;

              font-weight: 500;
              color: #666666;
            }

            .more-icon {
              // border: 1px solid red;
              margin-right: 10px;

              .elimage {
                width: 12px;
                height: 12px;
              }
            }
          }
        }

        .notice-left-line {
          margin-top: 10px;
          margin-bottom: 10px;
          border: 1px solid #cccccc;
        }

        .notice-left-content {
          position: relative;

          .elimage2 {
            position: absolute;
            top: 60px;
            left: 340px;
            width: 200px;
            height: 200px;
          }

          .conten-item {
            margin-bottom: 20px;
            cursor: pointer;

            .conten-item-title-title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-left: 20px;

              .elimage {
                margin-right: 6px;
                width: 27px;
                height: 27px;
              }

              .icon_num {
                margin-right: 6px;
                // padding-right: 4px;
                width: 24px;
                height: 24px;
                background: #409eff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .num {
                  margin-right: 4px;
                  font-size: 14px;
                  font-weight: bold;
                  font-style: italic;
                  color: #ffffff;
                }
              }

              .title {
                font-size: 16px;
                font-weight: 500;
                color: #000;
              }

              .title:hover {
                color: #3d84ff;
              }
            }

            .conten-item-title-time {
              margin-top: 4px;
              margin-right: 10px;
              padding-left: 52px;
              font-size: 12px;
              font-weight: 400;
              color: #999;
            }

            .conten-item-title-time:hover {
              color: #b29873;
            }
          }
        }
      }

      .notice-center {
        // border: 1px solid red;
        background: #f9f9f9;
        margin-left: 40px;

        .notice-center-head {
          margin-top: 10px;
          margin-bottom: 10px;

          display: flex;
          justify-content: space-between;
          align-items: center;

          //   border: 1px solid red;
          .notice-center-head-title {
            margin-left: 19px;
            font-size: 22px;

            font-weight: 400;
            color: #000000;
          }

          .notice-center-head-more {
            margin-right: 10px;
            width: 80px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #ededed;
            border-radius: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            user-select: none;
            cursor: pointer;

            .more-title {
              margin-left: 10px;
              font-size: 12px;

              font-weight: 500;
              color: #666666;
            }

            .more-icon {
              margin-right: 10px;

              .elimage {
                width: 12px;
                height: 12px;
              }
            }
          }
        }

        .notice-center-line {
          margin-top: 10px;
          margin-bottom: 10px;
          border: 1px solid #cccccc;
        }

        .notice-center-content {
          .conten-item {
            margin-bottom: 20px;

            .conten-item-title-title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-left: 20px;

              .elimage {
                margin-right: 6px;
                width: 27px;
                height: 27px;
              }

              .icon_num {
                margin-right: 6px;
                // padding-right: 4px;
                width: 24px;
                height: 24px;
                background: #409eff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .num {
                  margin-right: 4px;
                  font-size: 14px;
                  font-weight: bold;
                  font-style: italic;
                  color: #ffffff;
                }
              }

              .title {
                font-size: 16px;
                font-weight: 500;
                color: #000;
              }

              .title:hover {
                color: #3d84ff;
              }
            }

            .conten-item-title-time {
              margin-top: 4px;
              margin-right: 10px;
              padding-left: 52px;
              font-size: 12px;
              font-weight: 400;
              color: #999;
            }

            .conten-item-title-time:hover {
              color: #b29873;
            }
          }
        }
      }

      .notice-right {
        background: #f9f9f9;
        margin-left: 40px;

        .notice-center-head {
          margin-top: 10px;
          margin-bottom: 10px;

          display: flex;
          justify-content: space-between;
          align-items: center;

          //   border: 1px solid red;
          .notice-center-head-title {
            margin-left: 19px;
            font-size: 22px;

            font-weight: 400;
            color: #000000;
          }

          .notice-center-head-more {
            margin-right: 10px;
            width: 80px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #ededed;
            border-radius: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            user-select: none;
            cursor: pointer;

            .more-title {
              margin-left: 10px;
              font-size: 12px;

              font-weight: 500;
              color: #666666;
            }

            .more-icon {
              margin-right: 10px;

              .elimage {
                width: 12px;
                height: 12px;
              }
            }
          }
        }

        .notice-center-line {
          margin-top: 10px;
          margin-bottom: 10px;
          border: 1px solid #cccccc;
        }

        .notice-center-content {
          .conten-item {
            margin-bottom: 20px;

            .conten-item-title-title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-left: 20px;

              .elimage {
                margin-right: 6px;
                width: 27px;
                height: 27px;
              }

              .icon_num {
                margin-right: 6px;
                // padding-right: 4px;
                width: 24px;
                height: 24px;
                background: #409eff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .num {
                  margin-right: 4px;
                  font-size: 14px;
                  font-weight: bold;
                  font-style: italic;
                  color: #ffffff;
                }
              }

              .title {
                font-size: 16px;
                font-weight: 500;
                color: #000;
              }

              .title:hover {
                color: #3d84ff;
              }
            }

            .conten-item-title-time {
              margin-top: 4px;
              margin-right: 10px;
              padding-left: 52px;
              font-size: 12px;
              font-weight: 400;
              color: #999;
            }

            .conten-item-title-time:hover {
              color: #b29873;
            }
          }
        }
      }
    }

    .bottom_con {
      // border: 1px solid red;
      margin-top: 40px;
      margin-bottom: 60px;
      width: 100%;
      min-height: 460px;
      background: #f9f9f9;

      .head_con {
        padding-top: 14px;
        padding-left: 20px;
        padding-bottom: 16px;
        // width: 100%;
        height: 23px;

        border-bottom: 2px solid #cccccc;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          font-size: 24px;
          font-weight: 500;
          color: #000000;
        }

        .bt_right {
          margin-right: 10px;
          width: 80px;
          height: 28px;
          background: #ffffff;
          border: 1px solid #ededed;
          border-radius: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          user-select: none;
          cursor: pointer;

          .more-title {
            margin-left: 10px;
            font-size: 12px;

            font-weight: 500;
            color: #666666;
          }

          .elimage {
            margin-right: 10px;
            width: 12px;
            height: 12px;
          }
        }
      }

      .bt_con {
        padding-top: 25px;
        padding-left: 20px;
        margin-bottom: 30px;
        height: 17px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .bt {
          margin-right: 90px;
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          user-select: none;
          cursor: pointer;
        }

        .bt_active {
          color: #409eff;
        }
      }

      .con_body {
        // border: 1px solid red;
        // height: 305px;
        padding: 20px;

        .item_con {
          // border: 1px solid red;
          // height: 100%;
          // display: flex;
          // justify-content: flex-start;
          // align-items: center;
          // flex-wrap: wrap;
          // float: left;
          // width: calc((100% - 60px) / 3);

          // position: relative;
          width: 100%;
          overflow: hidden;

          .item {
            margin-right: 30px;
            margin-bottom: 20px;
            // border: 1px solid red;
            // display: flex;
            cursor: pointer;
            // justify-content: flex-start;
            // align-items: center;
            float: left;
            width: calc((100% - 90px) / 3);
            // height: 125px;
            overflow: hidden;

            .image-contain {
              width: 45%;
              height: 125px;
              float: left;
              position: relative;
              background: aliceblue;
              .elimage {
                display: inline-block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage:hover,
              .elimage:focus,
              .elimage:active {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              .elimage {
                width: 100%;
                height: 100%;
              }
            }

            .image-contain1 {
              width: 50%;
              height: 125px;
              float: left;
              position: relative;
              // background: #daebfc;
              text-align: center;
              background: aliceblue;
              .elimage {
                display: inline-block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage:hover,
              .elimage:focus,
              .elimage:active {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              .elimage {
                width: 70%;
                height: 100%;
              }
            }


            .item_r {
              // border: 1px solid red;
              height: 100%;
              width: 50%;
              float: left;
              // display: flex;
              // flex: 1;
              // flex-direction: column;
              // justify-content: flex-start;
              // align-items: flex-start;

              .item_t {
                margin-top: 4px;
                margin-left: 14px;
                font-size: 16px;
                font-weight: 500;
                color: #000000;
                // text-overflow: ellipsis;
                // white-space: nowrap;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }

              .item_c {
                margin-top: 20px;
                margin-left: 14px;

                // display: flex;
                // justify-content: flex-start;
                // align-items: center;

                .elimage {
                  width: 14px;
                  height: 14px;
                }

                .num {
                  font-size: 14px;
                  font-weight: 500;
                  color: #999999;
                  margin-bottom: 10px;
                }
              }
            }

            .china_class {
              position: absolute;
              top: 0px;
              right: 0;
              // left: 154px;
              background: #ff0c0c;
              font-size: 12px;
              color: #fff;
              width: 50px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              border-radius: 0px 0px 0px 14px;
            }

            .china_class_1 {
              position: absolute;
              top: 0px;
              right: 0;
              background: #4980fe;
              font-size: 12px;
              color: #fff;
              width: 50px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              border-radius: 0px 0px 0px 14px;
            }

            .china_class_2 {
              position: absolute;
              top: 0px;
              right: 0;
              background: #FBC248;
              font-size: 12px;
              color: #fff;
              width: 50px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              border-radius: 0px 0px 0px 14px;
            }
          }
        }
      }
    }
  }
}
</style>
