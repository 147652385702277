import request from '@/utils/request'

//获取我的在线课程列表
export function getmycourselist(params) {
  return request({
    url: '/portal/course-user/get-my-list',
    method: 'GET',
    params
  })
}

//获取课程列表
export function getCourseList(params) {
  return request({
    url: '/portal/course/get-list',
    method: 'GET',
    params
  })
}

//获取课程详情
export function getcourseinfo(params) {
  return request({
    url: '/portal/course/get-info',
    method: 'GET',
    params
  })
}

// 课程详情
export function getCourseSemesterSimpleInfo(params) {
  return request({
    url: '/portal/course-semester/get-my-simple-course-info',
    method: 'GET',
    params
  })
}

//获取虚拟仿真排行
export function getCourseRankList(params) {
  return request({
    url: '/portal/course/course-rank',
    method: 'GET',
    params
  })
}

//加入课程
export function joincourse(data) {
  return request({
    url: '/portal/course-user/join',
    method: 'post',
    data
  })
}
export function getRecCourseList(params) {
  return request({
    url: '/portal/course-semester/get-relevant-course-list',
    method: 'GET',
    params
  })
}

// 获取课期列表
export function getCourseSemesterList(params) {
  return request({
    url: '/portal/course-semester/get-list',
    method: 'GET',
    params
  })
}
// 获取课期详情
export function getCourseSemesterInfo(params) {
  return request({
    url: '/portal/course-semester/get-info',
    method: 'GET',
    params
  })
}
//获取课程标签相关的课程列表
export function getRecCourseSemesterList(params) {
  return request({
    url: '/portal/course-semester/get-relevant-course-list',
    method: 'GET',
    params
  })
}
//获取耕读类型课程的预约时间段
export function getCourseDateList(params) {
  return request({
    url: '/portal/course-semester/get-course-application-date',
    method: 'GET',
    params
  })
}


//直播回放列表
export function liveList(params) {
  return request({
    url: '/portal/live/get-record-list',
    method: 'GET',
    params
  })
}

//近期直播
export function getRecentList(params) {
  return request({
    url: '/portal/live/get-recent-list',
    method: 'GET',
    params
  })
}

//耕读类型学生报名
export function enterCourse(data) {
  return request({
    url: '/portal/course-user/enter',
    method: 'post',
    data
  })
}

//耕读类型课程学生取消报名
export function cancelEnterCourse(data) {
  return request({
    url: '/portal/course-user/cancel-enter',
    method: 'post',
    data
  })
}
